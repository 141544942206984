import React, { useState, useEffect } from 'react';
import { FaTh, FaList } from 'react-icons/fa';
import ProductCard from './ProductCard';
import './ProductListPage.scss';
import TopIcons from './TopIcons';
import Sidebar from './SideBar';
import { useAuth } from './AuthProvider';
import Masonry from 'react-masonry-css'; // Ensure this is imported

const base = process.env.REACT_APP_API_BASE_URL; // Add this line
const local = process.env.REACT_APP_LOCAL_URL;

const ProductListPage = ({ 
  products = [], 
  isCartPage = false, 
  isLikesPage = false,
  onRemoveFromCart,
  onRemoveFromLikes,
  onAddToCart
}) => {
  const [viewMode, setViewMode] = useState('grid');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { userId } = useAuth();
  const [statuses, setStatuses] = useState([]); // Add this line
  const [error, setError] = useState(null); // Add this line

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const checkStatuses = async (productIds) => {
    console.log('Checking statuses for products:', productIds);
    if (!userId) return;

    try {
      const url = `${base}/api/cart/checkStatuses`;
      const requestBody = { userId, productIds };
      console.log('Request Body:', requestBody);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to check statuses');
      }

      const data = await response.json();
      console.log('Statuses Response:', data); // Log the response data
      setStatuses(data);
    } catch (error) {
      console.error('Error checking statuses:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (products.length > 0 && userId) { // Only check statuses if userId is available
      const productIds = products.map(product => product.product_id);
      console.log('Product IDs for status check:', productIds);
      checkStatuses(productIds);
    }
  }, [userId, products]);

  const breakpointColumnsObj = {
    default: 5,
    1200: 4,
    1024: 3,
    768: 2,
    500: 2
  };

  return (
    <div className="product-list-page">
      
        <button className="toggle-sidebar-button" onClick={toggleSidebar}>
          ☰
        </button>
       
        <TopIcons />
        
      
      <div className={`sidebar ${isSidebarOpen ? 'visible' : ''}`}>
        <Sidebar />
      </div>
      
      <div className="content-wrapper">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
          enableResizableChildren={true}
          updateOnEachImageLoad={false}
        >
          {products.map((product, index) => {
            const status = statuses.find(s => s.productId === product.product_id) || {};
            const uniqueKey = `${product.product_id}_${index}`;
            
            return (
              <div key={uniqueKey} ref={product.ref}>
                <ProductCard
                  id={product.product_id}
                  image_url={product.image_url}
                  name={product.name}
                  retailer={product.retailer}
                  price={product.price}
                  rating={product.rating}
                  link={product.link}
                  isInCart={status.inCart}
                  onRemoveFromCart={onRemoveFromCart}
                  isLiked={status.isLiked}
                />
              </div>
            );
          })}
        </Masonry>
      </div>
      {error && <div className="error-message">{error}</div>} 
    </div>
  );
};

export default React.memo(ProductListPage);