import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CommentSection.scss';
import { useNavigate } from 'react-router-dom';

const CommentSection = ({ post, userId, onCommentCountChange, onClose, isFullScreen, show }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [userProfilePicture, setUserProfilePicture] = useState('');
  const local = process.env.REACT_APP_LOCAL_URL;
  const base = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchComments();
    fetchUserProfilePicture();
  }, [post.id, userId]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${base}/api/post/comments/${post.id}`);
      const commentsWithProfilePictures = await Promise.all(response.data.map(async (comment) => {
        const profilePictureResponse = await axios.get(`${base}/api/profile/profile-picture/${comment.user_id}`);
        return {
          ...comment,
          profile_picture_url: profilePictureResponse.data.signedUrl || null
        };
      }));
      setComments(commentsWithProfilePictures);
      onCommentCountChange(post.id, commentsWithProfilePictures.length);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const fetchUserProfilePicture = async () => {
    try {
      const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
      setUserProfilePicture(response.data.signedUrl);
    } catch (error) {
      console.error('Error fetching user profile picture:', error);
    }
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await axios.post(`${base}/api/post/comment`, {
        userId,
        postId: post.id,
        content: newComment
      });
      
      const userResponse = await axios.get(`${base}/api/profile/${userId}`);
      
      const newCommentObj = {
        id: response.data.commentId,
        user_id: userId,
        post_id: post.id,
        content: newComment,
        created_at: new Date().toISOString(),
        username: userResponse.data.username,
        profile_picture_url: userProfilePicture
      };
      
      setComments(prevComments => [newCommentObj, ...prevComments]);
      setNewComment('');
      onCommentCountChange(post.id, comments.length + 1);
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('comments-overlay')) {
      onClose();
    }
  };

  return (
    <>
      {show && <div className="comments-overlay" onClick={handleOverlayClick} />}
      <div className={`comment-section ${isFullScreen ? 'full-screen' : ''} ${show ? 'show' : ''}`}>
        <div className="comment-header">
          <h3>Comments</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="comments-list">
          {comments.length > 0 ? (
            comments.map(comment => (
              <div key={comment.id} className="comment">
                <img 
                  src={comment.profile_picture_url || 'path/to/default/avatar.png'} 
                  alt={comment.username} 
                  className="comment-avatar" 
                />
                <div className="comment-content">
                  <p 
                    className="comment-username"
                    onClick={() => handleUserClick(comment.user_id)}
                  >
                    {comment.username}
                  </p>
                  <p className="comment-text">{comment.content}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="no-comments-message">
              No comments yet. Be the first to comment!
            </div>
          )}
        </div>
        <form onSubmit={handleSubmitComment} className="comment-form">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
          />
          <button type="submit">Post</button>
        </form>
      </div>
    </>
  );
};

export default CommentSection;
