import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import './Login.scss';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(''); // New state for email
  const [error, setError] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false); // New state for registration
  const { login } = useAuth();
  const navigate = useNavigate();
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const endpoint = isRegistering ? `${base}/api/profile/register` : `${base}/api/profile/login`;
    const payload = isRegistering ? { username, password, email } : { username, password };

    try {
      const response = await axios.post(endpoint, payload);
      console.log("Registration response:", response.data); // Log the response data
      if (isRegistering) {
        const { userId } = response.data; // Get userId from the registration response
        console.log("Navigating to profile with userId:", userId); // Debugging log
        login(userId);
        navigate(`/profile/${userId}`); // Redirect to the user's profile
      } else {
        const id = response.data.userId;
        login(id);
        navigate('/');
      }
    } catch (err) {
      console.error("Registration error:", err); // Log the error
      setError(err.response?.data?.message || 'Operation failed.');
    }
  };

  return (
    <div className="login">
      <h2>{isRegistering ? 'Create Account' : 'Login'}</h2>
      <form onSubmit={handleSubmit}>
        {isRegistering && (
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot Password?
          </Link>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">{isRegistering ? 'Create Account' : 'Login'}</button>
        <p onClick={() => setIsRegistering(!isRegistering)} className="toggle-link">
          {isRegistering ? 'Already have an account? Login' : 'Create an account'}
        </p>
      </form>
    </div>
  );
};

export default Login;

