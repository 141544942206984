import React, { useState, useEffect } from 'react';
import './CartSideBar.scss';
import { FaChevronRight, FaHeart, FaShoppingCart, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const CartSideBar = ({ isOpen, toggleCartSidebar }) => {
    const { userId } = useAuth();
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const local = process.env.REACT_APP_LOCAL_URL;
    const base = process.env.REACT_APP_API_BASE_URL;

    const navigate = useNavigate();

    const goToCart = () => {
        navigate('/cart');
    };

    useEffect(() => {
      const fetchCartItems = async () => {
        if (!userId) {
          setIsLoading(false);
          return;
        }
    
        try {
          setIsLoading(true);
          const response = await fetch(`${base}/api/cart/getItems?userId=${userId}`);
    
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
          }
    
          const data = await response.json();
          setCartItems(data);
        } catch (error) {
          console.error('Error fetching cart items:', error);
          setError(error.message || 'An unexpected error occurred');
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchCartItems();

      const handleCartUpdate = () => {
          fetchCartItems(); // Fetch cart items when the event is triggered
      };

      window.addEventListener('cartUpdated', handleCartUpdate); // Listen for the custom event

      return () => {
          window.removeEventListener('cartUpdated', handleCartUpdate); // Clean up the event listener
      };
    }, [userId]);

  const removeFromCart = async (productId) => {
      try {
            const response = await fetch(`${base}/api/cart/removeItem`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId, productId }),
          });

          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }

          setCartItems(cartItems.filter(item => item.product_id !== productId));
      } catch (error) {
          console.error('Error removing item from cart:', error);
          setError(`Failed to remove item from cart: ${error.message}`);
      }
  };


    return (
        <div className={`cart-sidebar ${isOpen ? 'visible' : ''}`}>
            <FaChevronRight className="close-icon" onClick={toggleCartSidebar} />
            <FaShoppingCart className="go-to-cart" onClick={goToCart} />

            <h2 className="cart-title" onClick={goToCart}>Cart</h2>

            <div className="cart-items">
                {isLoading ? (
                    <p>Loading cart items...</p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : cartItems.length === 0 ? (
                    <p>Your cart is empty</p>
                ) : (
                    cartItems.map((item) => (
                        <div key={item.product_id} className="cart-item">
                            <div className="left-section">
                                <img src={item.image_url} alt={item.name} className="item-image" />
                                {/* <div className="item-actions">
                                    <FaHeart className="like-icon" />
                                    <FaTrashAlt className="delete-icon" onClick={() => removeFromCart(item.product_id)} />
                                </div> */}
                            </div>
                            <div className="right-section">
                                <h4 className="item-name">
                                    <span 
                                        onClick={() => window.open(item.link, '_blank')}
                                        style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                                    >
                                        {item.name}
                                    </span>
                                </h4>
                                <p className="item-seller">{item.retailer}</p>
                                <p className="item-price">${item.price}</p>
                                <div className="item-rating">
                                    <span>{item.rating} </span>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <button className="checkout-button" onClick={goToCart}>
                Checkout
            </button>
        </div>
    );
};

export default CartSideBar;
