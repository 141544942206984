import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem('authToken');
      const storedUserId = localStorage.getItem('userId');
      const expirationTime = localStorage.getItem('authTokenExpiration');

      if (token && storedUserId && expirationTime) {
        if (new Date().getTime() < parseInt(expirationTime, 10)) {
          setIsAuthenticated(true);
          setUserId(storedUserId);
        } else {
          // Token expired, clear it
          logout();
        }
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  const login = (newUserId) => {
    const expirationTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 1 week from now
    setIsAuthenticated(true);
    setUserId(newUserId);
    localStorage.setItem('authToken', 'user-auth-token');
    localStorage.setItem('userId', newUserId);
    localStorage.setItem('authTokenExpiration', expirationTime.toString());
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserId(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('authTokenExpiration');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
