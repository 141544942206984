import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LikesList.scss';

const LikesList = ({ postId, onClose, onCloseAndNavigate, isFullScreen, show }) => {
  const [likes, setLikes] = useState([]);
  const local = process.env.REACT_APP_LOCAL_URL;
  const base = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('likes-overlay')) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      fetchLikes();
    }
  }, [postId, show]);

  const fetchLikes = async () => {
    try {
      const response = await axios.get(`${base}/api/post/count/likes/${postId}`);
      console.log('Likes data:', response.data.likes); // Debugging: Log the likes data
      const likesWithProfilePictures = await Promise.all(response.data.likes.map(async (like) => {
        const profilePictureResponse = await fetchUserProfilePicture(like.id); // Ensure 'id' is used here
        return {
          ...like,
          profile_picture_url: profilePictureResponse || 'path/to/default/avatar.png'
        };
      }));
      setLikes(likesWithProfilePictures);
    } catch (error) {
      console.error('Error fetching likes:', error);
    }
  };

  const fetchUserProfilePicture = async (userId) => {
    if (!userId) {
      console.error('User ID is undefined');
      return null;
    }
    try {
      const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
      return response.data.signedUrl;
    } catch (error) {
      console.error('Error fetching user profile picture:', error);
      return null;
    }
  };

  const handleUserClick = (userId) => {
    onCloseAndNavigate(true); // Close LikesList and FullScreenPost
    navigate(`/profile/${userId}`);
  };

  return (
    <>
      {show && <div className="likes-overlay" onClick={handleOverlayClick} />}
      <div className={`likes-list ${isFullScreen ? 'full-screen' : ''} ${show ? 'show' : ''}`}>
        <div className="likes-header">
          <h3>Likes</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="likes-content">
          {Array.isArray(likes) && likes.length > 0 ? (
            likes.map(like => (
              <div key={like.id} className="like-item" onClick={() => handleUserClick(like.id)}>
                <img 
                  src={like.profile_picture_url || 'path/to/default/avatar.png'} 
                  alt={like.username} 
                  className="like-avatar" 
                />
                <span className="like-username">{like.username}</span>
              </div>
            ))
          ) : (
            <div className="no-likes-message">
              No likes yet
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LikesList;
