import React, { useState, useEffect } from 'react';
import ProductListPage from './ProductListPage';
import './LikesPage.scss';
import { useAuth } from './AuthProvider';
import axios from 'axios';

const LikesPage = () => {
  const { userId } = useAuth();
  const [likes, setLikes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;

  useEffect(() => {
    fetchLikes();
  }, [userId]);

  const fetchLikes = async () => {
    if (!userId) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.get(`${base}/api/likes/getLikes?userId=${userId}`);
      console.log('Fetched liked products:', response.data); // Ensure product_id is present
      setLikes(response.data);
    } catch (error) {
      console.error('Error fetching liked products:', error);
      setError(`Failed to fetch liked products: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFromLikes = async (productId) => {
    try {
      await axios.delete(`${base}/api/likes/removeLike`, {
        data: { userId, productId }
      });
      setLikes(likes.filter(product => product.product_id !== productId)); // Use product.product_id
    } catch (error) {
      console.error('Error removing product from likes:', error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      await axios.delete(`${base}/api/cart/removeItem`, {
        data: { userId, productId }
      });
      // Update the local state to reflect the change
      setLikes(likes.map(product => 
        product.id === productId ? { ...product, inCart: false } : product
      ));
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleAddToCart = async (productId) => {
    try {
      const productToAdd = likes.find(product => product.id === productId);
      if (!productToAdd) {
        throw new Error('Product not found');
      }

      await axios.post(`${base}/api/cart/addItem`, {
        userId, 
        productId: productToAdd.id,
        name: productToAdd.name,
        price: productToAdd.price,
        retailer: productToAdd.retailer,
        link: productToAdd.link,
        rating: productToAdd.rating,
        image_url: productToAdd.image_url,
        quantity: 1 // You might want to add a quantity field if it's not already included
      });

      // Update the local state to reflect the change
      setLikes(likes.map(product => 
        product.id === productId ? { ...product, inCart: true } : product
      ));
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  if (isLoading) {
    return <div className="likes-page"><div className="loading">Loading...</div></div>;
  }

  if (error) {
    return <div className="likes-page"><div className="error">Error: {error}</div></div>;
  }

  return (
    <div className="likes-page">
      <div className="sticky-header-container">
        <h1 className="page-title">Your Likes</h1>
      </div>
      <ProductListPage 
        products={likes.map(product => ({ ...product, product_id: product.product_id }))}
        isLikesPage={true}
        onRemoveFromLikes={handleRemoveFromLikes}
        onRemoveFromCart={handleRemoveFromCart}
        onAddToCart={handleAddToCart}
      />
    </div>
  );
};

export default LikesPage;
