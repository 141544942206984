import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { FaBookmark } from 'react-icons/fa';
import ProductListPage from './ProductListPage';
import './ResultsPage.scss';
import SearchBar from './SearchBar';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import LoadingSpinner from './components/LoadingSpinner';

const ResultsPage = ({ onAddToCart, onRemoveFromCart }) => {
  const location = useLocation();
  const { query, resultsProducts } = location.state || {};
  const { isAuthenticated, userId } = useAuth(); // Get authentication status
  const [showModal, setShowModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const modalRef = useRef();
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const observer = useRef();
  const [totalResults, setTotalResults] = useState(0);
  
  // Create a callback ref for the last element
  const lastProductElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        // Increased preload threshold to load more content earlier
        const preloadThreshold = allProducts.length - (page * 100 * 0.8); // Increased from 0.5 to 0.8
        if (entries[0].target.offsetTop <= preloadThreshold) {
          loadMore();
        }
      }
    }, { 
      threshold: 0.1, // Reduced threshold to trigger earlier
      rootMargin: '1000px' // Increased from 500px to 1000px to start loading earlier
    });
    
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, page, allProducts.length]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  useEffect(() => {
    if (resultsProducts) {
      setAllProducts(resultsProducts);
      setTotalResults(resultsProducts.length);
      setIsLoading(false);
    }
  }, [resultsProducts]);

  // Modify loadMore to handle multiple pages at once
  const loadMore = async () => {
    if (isLoading) return;
    
    try {
      setIsLoading(true);
      const url = `${base}/api/search/query`;
      
      // Make two parallel requests for better preloading
      const [firstPage, secondPage] = await Promise.all([
        axios.post(url, {
          query,
          start: page * 100
        }),
        axios.post(url, {
          query,
          start: (page + 1) * 100
        })
      ]);
      
      const combinedProducts = [
        ...firstPage.data.results,
        ...secondPage.data.results
      ].map(product => ({
        ...product,
        product_id: product.id,
        link: product.direct_link || product.link
      }));

      if (combinedProducts.length === 0 || 
          !secondPage.data.nextStart) {
        console.log('Reached end of results because:', {
          noResults: combinedProducts.length === 0,
          noNextStart: !secondPage.data.nextStart
        });
        setHasMore(false);
      }
      
      setAllProducts(prev => {
        const updatedProducts = [...prev, ...combinedProducts];
        setTotalResults(updatedProducts.length);
        return updatedProducts;
      });
      
      // Increment page by 2 since we loaded two pages
      setPage(prev => prev + 2);
    } catch (error) {
      console.error('Full error details:', error.response || error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Add initial preload
  useEffect(() => {
    if (resultsProducts && resultsProducts.length > 0 && !isLoading) {
      // Preload next batch after initial load
      loadMore();
    }
  }, [resultsProducts]);

  const handleSaveClick = () => {
    if (!isAuthenticated || !userId) {
      console.warn('User must be logged in to save searches');
      return; // Allow access to results but prevent saving
    }
    setSearchTitle(query);
    setShowModal(true);
  };

  const handleSaveSearch = async () => {
    const newSearch = { 
      query, 
      searchResults: resultsProducts,
      title: searchTitle
    };

    try {
      const response = await axios.post(`${base}/api/saved-searches/save/${userId}`, newSearch);
      console.log('Search save response:', response.data);
      window.dispatchEvent(new Event('savedSearchUpdated'));
      setShowModal(false);
    } catch (error) {
      console.error('Failed to save search', error.response || error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      await axios.delete(`${base}/api/cart/removeItem`, {
        data: { userId, productId }
      });
      // Update the resultsProducts state to reflect the change
      resultsProducts = resultsProducts.filter(product => product.id !== productId); // Ensure you update the state correctly
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  console.log('Results Products:', resultsProducts); // Log the resultsProducts

  return (
    <div className="results-page">
      <div className="sticky-search-container">
        <div className="search-bar-wrapper">
          <SearchBar initialQuery={query} />
        </div>
      </div>
      
      <div className="save-search-container">
        <button 
          className="save-search-button" 
          onClick={handleSaveClick} 
          disabled={!isAuthenticated}
          title={!isAuthenticated ? "Login to save searches" : "Save Search"}
        >
          <FaBookmark /> Save Search
        </button>
      </div>
      
      <ProductListPage 
        products={allProducts.map((product, index) => {
          // Add ref to an element before the end to trigger earlier loading
          if (allProducts.length - 20 === index) {
            return { ...product, ref: lastProductElementRef };
          }
          return product;
        })} 
        onAddToCart={onAddToCart}
        onRemoveFromCart={handleRemoveFromCart}
      />

      {isLoading && (
        <div className="loading-indicator">
          <LoadingSpinner size="medium" />
        </div>
      )}

      {!hasMore && totalResults > 0 && (
        <div className="results-summary">
          End of results • {totalResults} items found for "{query}"
        </div>
      )}
      
      {showModal && (
        <div className="modal-overlay-save-search">
          <div className="modal-save-search" ref={modalRef}>
            <h2>Save Search</h2>
            <input
              type="text"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="search-title-input"
            />
            <div className="modal-actions">
              <button onClick={() => setShowModal(false)}>Cancel</button>
              <button onClick={handleSaveSearch}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;


