import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FollowListModal.scss';
import { FiX } from 'react-icons/fi';
import { useAuth } from './AuthProvider'; // Import useAuth

const FollowListModal = ({ type, list, onClose }) => {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const { userId: authUserId } = useAuth(); // Get the authenticated user's ID
    const [followList, setFollowList] = useState(list);
    const defaultProfilePicture = "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=";
    const local = process.env.REACT_APP_LOCAL_URL;
    const base = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleUserClick = (userId) => {
        navigate(`/profile/${userId}`);
        onClose();
    };

    useEffect(() => {
        const fetchProfilePicturesAndFollowStatus = async () => {
            const updatedList = await Promise.all(list.map(async (user) => {
                let updatedUser = { ...user };
                if (user.profile_picture_url) {
                    try {
                        const response = await axios.get(`${base}/api/profile/profile-picture/${user.id}`);
                        if (response.data.signedUrl) {
                            updatedUser.signedProfilePictureUrl = response.data.signedUrl;
                        }
                    } catch (error) {
                        console.error('Error fetching profile picture:', error);
                    }
                }
                // Check if the authenticated user is following this user
                if (authUserId && authUserId !== user.id) {
                    try {
                        const followResponse = await axios.get(`${base}/api/follow/check-following`, {
                            params: { followerId: authUserId, followedId: user.id }
                        });
                        updatedUser.isFollowing = followResponse.data.isFollowing;
                    } catch (error) {
                        console.error('Error checking follow status:', error);
                    }
                }
                return updatedUser;
            }));
            setFollowList(updatedList);
        };

        fetchProfilePicturesAndFollowStatus();
    }, [list, base, authUserId]);

    const handleFollowToggle = async (userId, currentlyFollowing) => {
        try {
            if (currentlyFollowing) {
                await axios.delete(`${base}/api/follow/unfollow`, { 
                    data: { followerId: authUserId, followedId: userId } 
                });
            } else {
                await axios.post(`${base}/api/follow/follow`, { 
                    followerId: authUserId, followedId: userId 
                });
            }
            // Update the local state
            setFollowList(prevList => 
                prevList.map(user => 
                    user.id === userId 
                        ? { ...user, isFollowing: !currentlyFollowing } 
                        : user
                )
            );
        } catch (error) {
            console.error('Error toggling follow:', error);
        }
    };

    return (
        <div className="follow-list-modal-overlay">
            <div className="follow-list-modal" ref={modalRef}>
                <button className="close-button" onClick={onClose}>
                    <FiX />
                </button>
                <h2>{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
                <div className="follow-list">
                    {followList.map((user) => (
                        <div key={user.id} className="follow-item">
                            <img 
                                src={user.signedProfilePictureUrl || defaultProfilePicture} 
                                alt={user.username}
                                crossOrigin="anonymous"
                                onError={(e) => {
                                    console.error('Error loading image:', e.target.src);
                                    e.target.src = defaultProfilePicture;
                                }}
                            />
                            <span 
                                className="username clickable"
                                onClick={() => handleUserClick(user.id)}
                            >
                                {user.username}
                            </span>
                            {authUserId && authUserId !== user.id && (
                                <button 
                                    className={`follow-button ${user.isFollowing ? 'following' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFollowToggle(user.id, user.isFollowing);
                                    }}
                                >
                                    {user.isFollowing ? 'Unfollow' : 'Follow'}
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FollowListModal;
