import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import './SideBar.scss';
import { FaTrash, FaCheck } from 'react-icons/fa';

const Sidebar = () => {
    const [savedSearches, setSavedSearches] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, userId } = useAuth();
    const [currentQuery, setCurrentQuery] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const local = process.env.REACT_APP_LOCAL_URL;
    const base = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (isAuthenticated && userId) {
            loadSavedSearches();
        } else {
            setSavedSearches([]);
        }

        window.addEventListener('savedSearchUpdated', loadSavedSearches);

        return () => {
            window.removeEventListener('savedSearchUpdated', loadSavedSearches);
        };
    }, [isAuthenticated, userId]);

    const loadSavedSearches = async () => {
        try {
            const response = await axios.get(`${base}/api/saved-searches/get/${userId}`);
            setSavedSearches(response.data);
        } catch (error) {
            console.error('Failed to load saved searches', error.response || error);
        }
    };

    const deleteSearch = async (searchId) => {
        if (!isAuthenticated || !userId) {
            console.warn('User must be logged in to delete searches');
            return;
        }

        try {
            await axios.delete(`${base}/api/saved-searches/delete/${userId}/${searchId}`);
            await loadSavedSearches();
        } catch (error) {
            console.error('Failed to delete search', error.response || error);
        }
    };

    const handleRename = async (searchId) => {
        if (!isAuthenticated || !userId) {
            console.warn('User must be logged in to rename searches');
            return;
        }

        try {
            await axios.put(`${base}/api/saved-searches/rename/${userId}/${searchId}`, { title: editTitle });
            setEditingId(null);
            await loadSavedSearches();
        } catch (error) {
            console.error('Failed to rename search', error.response || error);
        }
    };

    const handleSavedSearchClick = (search) => {
        setCurrentQuery(search.query);
        navigate('/results', { 
            state: { 
                query: search.query, 
                resultsProducts: search.searchResults 
            } 
        });
    };

    const handleTitleClick = (e, search) => {
        e.stopPropagation();
        setEditingId(search.id);
        setEditTitle(search.title || search.query);
    };

    return (
        <div className="sidebar-content">
            <h2>Saved Searches</h2>
            {isAuthenticated ? (
                <div className="conversations">
                    <ul className="conversation-list">
                        {savedSearches.map((search) => (
                            <li key={search.id} className="conversation-item">
                                <div className="conversation-content" onClick={() => handleSavedSearchClick(search)}>
                                    {editingId === search.id ? (
                                        <input
                                            type="text"
                                            value={editTitle}
                                            onChange={(e) => setEditTitle(e.target.value)}
                                            onKeyPress={(e) => e.key === 'Enter' && handleRename(search.id)}
                                            onBlur={() => handleRename(search.id)}
                                            className="edit-title-input"
                                            autoFocus
                                        />
                                    ) : (
                                        <div 
                                            className="query"
                                            onClick={(e) => handleTitleClick(e, search)}
                                        >
                                            {search.title || search.query}
                                        </div>
                                    )}
                                    <div className="timestamp">{new Date(search.timestamp).toLocaleString()}</div>
                                </div>
                                <div className="action-buttons">
                                    <button 
                                        className="delete-button" 
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            deleteSearch(search.id); 
                                        }}
                                    >
                                        <FaTrash />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>Please log in to save and view your searches.</p>
            )}
        </div>
    );
};

export default Sidebar;
