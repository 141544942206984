// TopIcons.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaHeart, FaShoppingCart, FaPhotoVideo } from 'react-icons/fa';
import './TopIcons.scss';
import CartSideBar from './CartSideBar';
import Sidebar from './SideBar';
import { useAuth } from './AuthProvider'; // Import useAuth

const TopIcons = ({ iconColor = '#3d3534', hoverColor = '#007bff' }) => {
  const navigate = useNavigate();
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isAuthenticated, userId } = useAuth(); // Get authentication status and userId
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Add useEffect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCartSidebar = () => {
    if (isMobile && isSidebarOpen) {
      setIsSidebarOpen(false);
    }
    setIsCartSidebarOpen(!isCartSidebarOpen);
  };

  const toggleSidebar = () => {
    if (isMobile && isCartSidebarOpen) {
      setIsCartSidebarOpen(false);
    }
    setIsSidebarOpen(!isSidebarOpen);
  };

  const goToHome = () => {
    navigate('/');
  };

  const goToLikes = () => {
    navigate('/likes'); 
  };

  const goToProfile = () => {
    if (isAuthenticated) {
      navigate(`/profile/${userId}`); // Navigate to profile with userId if authenticated
    } else {
      navigate('/login'); // Navigate to login if not authenticated
    }
  };

  const goToDiscover = () => {
    navigate('/discover');
  };

  return (
    <>
      <button 
        className="toggle-sidebar-button" 
        onClick={toggleSidebar}
        style={{ color: iconColor }}
      >
        ☰
      </button>
      <div className="top-icons" style={{ '--icon-color': iconColor, '--hover-color': hoverColor }}>
        <FaHome className="icon" onClick={goToHome} />
        <FaPhotoVideo className="icon" onClick={goToDiscover} /> {/* New Discover icon */}
        <FaShoppingCart className="icon" onClick={toggleCartSidebar} />
        <FaHeart className="icon" onClick={goToLikes} />
        <FaUser className="icon" onClick={goToProfile} /> {/* Profile icon */}
      </div>

      <CartSideBar isOpen={isCartSidebarOpen} toggleCartSidebar={toggleCartSidebar} />
      <div className={`sidebar ${isSidebarOpen ? 'visible' : ''}`}>
        <Sidebar />
      </div>
    </>
  );
};

export default TopIcons;
