import React, { useState, useEffect } from 'react';
import { FaShoppingCart, FaHeart } from 'react-icons/fa';
import './ProductCard.scss';
import { useAuth } from './AuthProvider';

const ProductCard = ({ 
  id, 
  image_url, 
  name, 
  retailer, 
  price, 
  rating, 
  link, 
  isListView, 
  isInCart,
  onRemoveFromCart,
  isLiked: initialIsLiked
}) => {
  const { userId } = useAuth();
  const [inCart, setInCart] = useState(isInCart);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLiked, setIsLiked] = useState(initialIsLiked); // Use the renamed prop
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isImagePreloaded, setIsImagePreloaded] = useState(false);


  // Update local state when isInCart prop changes
  useEffect(() => {
    setInCart(isInCart);
  }, [isInCart]);

  // Update local state when isLiked prop changes
  useEffect(() => {
    setIsLiked(initialIsLiked); // Update isLiked when the prop changes
  }, [initialIsLiked]); // Add initialIsLiked to the dependency array

  // Add preload effect
  useEffect(() => {
    const img = new Image();
    img.src = image_url;
    img.onload = () => {
      setIsImagePreloaded(true);
      setImageLoaded(true);
    };
  }, [image_url]);

  const handleCartClick = async () => {
    if (!userId) {
      setError('User not logged in');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      if (inCart) { // If the item is already in the cart
        await onRemoveFromCart(id); // Pass the product ID to remove it
        setInCart(false); // Update local state to reflect removal
        window.dispatchEvent(new Event('cartUpdated'));
      } else {
        const url = `${base}/api/cart/addItem`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userId, 
            productId: id,
            quantity: 1,
            name,
            price,
            retailer,
            link,
            rating,
            image_url,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
        }

        setInCart(true); // Update local state to reflect addition
        window.dispatchEvent(new Event('cartUpdated'));
      }
    } catch (error) {
      console.error('Error updating cart:', error);
      setError(`Failed to ${inCart ? 'remove from' : 'add to'} cart`);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to process price
  const processPrice = (price) => {
    if (typeof price === 'number') {
      return price.toFixed(2);
    }
    if (typeof price === 'string') {
      // Remove any non-numeric characters except for the decimal point
      const numericPrice = price.replace(/[^\d.]/g, '');
      return parseFloat(numericPrice).toFixed(2);
    }
    return '0.00'; // Default value if price is undefined or invalid
  };

  const handleHeartClick = async () => {
    if (!userId) {
      setError('User not logged in');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const url = isLiked
        ? `${base}/api/likes/remove`
        : `${base}/api/likes/add`;

      const method = isLiked ? 'DELETE' : 'POST';

      // Update local state immediately
      setIsLiked(!isLiked); // Toggle the liked state

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userId, 
          productId: id,
          name,
          price,
          retailer,
          link,
          rating,
          image_url,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
      }

      // Optionally, you can call a callback function passed as a prop to update the liked status in the parent component
    } catch (error) {
      console.error('Error updating likes:', error);
      setError(`Failed to ${isLiked ? 'unlike' : 'like'} product`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();
    
    // If we have a direct link in the product data, use it immediately
    if (link && link.includes('google.com/url?')) {
      window.open(link, '_blank');
      return;
    }

    // Only fetch from API if we don't have a direct link
    try {
      const response = await fetch(`${base}/api/search/product/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      if (data.directLink) {
        window.open(data.directLink, '_blank');
      } else {
        window.open(link, '_blank');
      }
    } catch (error) {
      console.error('Error fetching the direct link:', error);
      window.open(link, '_blank');
    }
  };

  return (
    <div className={`product-card ${isListView ? 'list-view' : ''}`}>
      <div className="image-container">
        {!isImagePreloaded && (
          <div className="image-placeholder">
            <div className="shimmer-effect"></div>
          </div>
        )}
        <img 
          src={image_url} 
          alt={name} 
          className={`product-card-image ${isImagePreloaded ? 'loaded' : 'loading'}`}
          style={{ display: isImagePreloaded ? 'block' : 'none' }}
        />
      </div>
      <div className="product-card-content">
        <h2 className="product-card-name">
          <span className="clickable-link" onClick={handleLinkClick}>
            {name}
          </span>
        </h2>
        <h3 className="product-card-seller">{retailer}</h3>
        <div className="product-card-rating">
          {Array(Math.floor(rating)).fill().map((_, i) => (
            <span key={i}>&#9733;</span>
          ))}
        </div>
        <p className="product-card-price">${processPrice(price)}</p>
        <p className="product-card-description">
          {/* Add a brief description here if available */}
        </p>
      </div>

      <div className={`product-card-icons ${isListView ? 'list-view-icons' : 'grid-view-icons'}`}>
        <FaHeart 
          className={`product-card-icon heart-icon ${isLiked ? 'liked' : ''}`} 
          onClick={handleHeartClick}
          style={{ 
            opacity: isLoading ? 0.5 : 1, 
            pointerEvents: isLoading ? 'none' : 'auto',
            color: isLiked ? 'red' : 'inherit' // Add this line
          }}
        />
        <FaShoppingCart 
          className={`product-card-icon cart-icon ${inCart ? 'in-cart' : ''}`}
          onClick={handleCartClick}
          style={{ 
            opacity: isLoading ? 0.5 : 1, 
            pointerEvents: isLoading ? 'none' : 'auto',
            color: inCart ? 'green' : 'inherit' // Add this line
          }}
        />
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default React.memo(ProductCard);
