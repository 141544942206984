import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import './LinksList.scss';

const LinksList = ({ links = [], onClose, show }) => {
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('links-overlay')) {
            onClose();
        }
    };

    return (
        <>
            {show && <div className="links-overlay" onClick={handleOverlayClick} />}
            <div className={`links-section full-screen ${show ? 'show' : ''}`}>
                <div className="links-header">
                    <h3>Links</h3>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="links-list">
                    {links && links.length > 0 ? (
                        links.map((link, index) => (
                            <div key={index} className="link-item">
                                <span className="link-description">{link.label}</span>
                                <a 
                                    href={link.url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="link-icon"
                                    title={link.url}
                                >
                                    <FiExternalLink />
                                </a>
                            </div>
                        ))
                    ) : (
                        <div className="no-links-message">
                            No links available for this post
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LinksList; 