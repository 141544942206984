import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaComment, FaHeart, FaRegHeart, FaTimes, FaLink, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import CommentSection from './CommentSection';
import './FullScreenPost.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LikesList from './LikesList'; // We'll create this component
import LinksList from './LinksList';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const FullScreenPost = ({ posts, initialPostIndex, onClose, onLike, userId, onCommentCountChange, initialShowComments = false, onIndexChange }) => {
  const [showComments, setShowComments] = useState(initialShowComments);
  const [currentIndex, setCurrentIndex] = useState(initialPostIndex);
  const currentPost = posts[currentIndex];
  const touchStartY = useRef(null);
  const lastScrollTime = useRef(0);
  const scrollCooldown = 300; // ms cooldown between scrolls
  const isMobile = window.innerWidth <= 768; // Define mobile breakpoint
  const [showFullCaption, setShowFullCaption] = useState(false);
  const [userProfilePictures, setUserProfilePictures] = useState({});
  const local = process.env.REACT_APP_LOCAL_URL;
  const base = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [showLikes, setShowLikes] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [postLinks, setPostLinks] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [saveCount, setSaveCount] = useState(0);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const touchStartX = useRef(null);
  const [isHorizontalSwipe, setIsHorizontalSwipe] = useState(false);

  useEffect(() => {
    // Disable body scrolling when component mounts
    document.body.style.overflow = 'hidden';
    // Re-enable body scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    fetchUserProfilePictures();
  }, [posts]);

  const fetchUserProfilePictures = async () => {
    const uniqueUserIds = [...new Set(posts.map(post => post.user_id))];
    const profilePictures = {};

    for (const userId of uniqueUserIds) {
      try {
        const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
        profilePictures[userId] = response.data.signedUrl;
      } catch (error) {
        console.error('Error fetching user profile picture:', error);
        profilePictures[userId] = null;
      }
    }

    setUserProfilePictures(profilePictures);
  };

  const handleLike = () => {
    const currentPost = posts[currentIndex];
    if (currentPost.user_id === userId) {
      // If it's the user's own post, show likes list
      setShowLikes(!showLikes);
      setShowComments(false);
      setShowLinks(false);
      setShowSaved(false);
    } else {
      // For other posts, like/unlike and update local state
      onLike(currentPost.id);
    }
  };

  const toggleComments = (e) => {
    e.stopPropagation();
    setShowComments(!showComments);
    setShowLikes(false); // Close likes list if open
    setShowLinks(false);
    setShowSaved(false);
    console.log('showLinks:', showLinks);
  };

  const closeComments = () => {
    setShowComments(false);
  };

  const closeLikes = () => {
    setShowLikes(false);
  };

  const closeLinks = () => {
    setShowLinks(false);
  };

  const handleScroll = (direction) => {
    if (isMobile && showComments) return; // Prevent scrolling only on mobile when comments are open

    const now = Date.now();
    if (now - lastScrollTime.current < scrollCooldown) {
      return; // Ignore scroll if we're still in cooldown
    }
    
    let newIndex = currentIndex;
    if (direction === 'up' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === 'down' && currentIndex < posts.length - 1) {
      newIndex = currentIndex + 1;
    }
    
    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
      onIndexChange(newIndex); // Notify parent component of index change
    }
    
    lastScrollTime.current = now;
  };

  const handleWheel = (e) => {
    if (isMobile && showComments) return; // Prevent scrolling only on mobile when comments are open

    e.preventDefault();
    if (Math.abs(e.deltaY) > 50) { // Increased threshold for wheel scrolling
      if (e.deltaY > 0) {
        handleScroll('down');
      } else {
        handleScroll('up');
      }
    }
  };

  const handleTouchStart = (e) => {
    if (isMobile && showComments) return;
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
    setIsHorizontalSwipe(false);
  };

  const handleTouchMove = (e) => {
    if ((isMobile && showComments) || !touchStartX.current || !touchStartY.current) return;

    const touchEndX = e.touches[0].clientX;
    const touchEndY = e.touches[0].clientY;
    const deltaX = touchEndX - touchStartX.current;
    const deltaY = touchEndY - touchStartY.current;

    // Determine if this is a horizontal or vertical swipe
    if (!isHorizontalSwipe && Math.abs(deltaX) > Math.abs(deltaY)) {
      setIsHorizontalSwipe(true);
    }

    if (isHorizontalSwipe) {
      // Handle horizontal swipe for images
      e.preventDefault(); // Prevent vertical scrolling when swiping horizontally
      if (Math.abs(deltaX) > 50) { // Threshold for swipe
        if (deltaX > 0) {
          handleMediaScroll('prev');
        } else {
          handleMediaScroll('next');
        }
        // Reset touch coordinates after handling swipe
        touchStartX.current = null;
        touchStartY.current = null;
      }
    } else {
      // Handle vertical swipe for posts
      if (Math.abs(deltaY) > 100) {
        if (deltaY > 0) {
          handleScroll('up');
        } else {
          handleScroll('down');
        }
        touchStartY.current = null;
        touchStartX.current = null;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = null;
    touchStartY.current = null;
    setIsHorizontalSwipe(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isMobile && showComments) return; // Prevent key handling on mobile when comments are open

      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          handleScroll('up');
          break;
        case 'ArrowDown':
          e.preventDefault();
          handleScroll('down');
          break;
        case 'ArrowLeft':
          e.preventDefault();
          // Only handle left/right if there are multiple media items
          if (currentPost?.media?.length > 1) {
            handleMediaScroll('prev');
          }
          break;
        case 'ArrowRight':
          e.preventDefault();
          if (currentPost?.media?.length > 1) {
            handleMediaScroll('next');
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, showComments, isMobile, currentPost?.media?.length]); // Add currentPost?.media?.length to dependencies

  const handleCloseFullScreen = (e) => {
    e.stopPropagation();
    onClose();
    setShowComments(false); // Close comments
    setShowLikes(false); // Close likes list
  };

  const toggleCaption = (e) => {
    e.stopPropagation();
    setShowFullCaption(!showFullCaption);
  };

  const handleShareLink = (e) => {
    e.stopPropagation();
    setShowLinks(!showLinks);
    setShowComments(false);
    setShowLikes(false);
    setShowSaved(false);
  };

  const fetchUserProfilePicture = useCallback(async (userId) => {
    if (userProfilePictures[userId]) return userProfilePictures[userId];

    try {
      const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
      const newProfilePictures = { ...userProfilePictures, [userId]: response.data.signedUrl };
      setUserProfilePictures(newProfilePictures);
      return response.data.signedUrl;
    } catch (error) {
      console.error('Error fetching user profile picture:', error);
      return null;
    }
  }, [base, userProfilePictures]);

  useEffect(() => {
    const fetchProfilePictures = async () => {
      const currentPost = posts[currentIndex];
      const prevPost = posts[currentIndex - 1];
      const nextPost = posts[currentIndex + 1];

      const postsToFetch = [currentPost, prevPost, nextPost].filter(Boolean);
      const uniqueUserIds = [...new Set(postsToFetch.map(post => post.user_id))];

      for (const userId of uniqueUserIds) {
        if (!userProfilePictures[userId]) {
          await fetchUserProfilePicture(userId);
        }
      }
    };

    fetchProfilePictures();
  }, [currentIndex, posts, fetchUserProfilePicture, userProfilePictures]);

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  // Add this new useEffect hook
  useEffect(() => {
    console.log('showLikes:', showLikes);
  }, [showLikes]);

  const handleLikesListClose = (shouldCloseFullScreen = false) => {
    setShowLikes(false);
    if (shouldCloseFullScreen) {
      onClose();
    }
  };

  useEffect(() => {
    const fetchLinks = async () => {
      if (!currentPost?.id) return;
      
      try {
        const response = await axios.get(`${base}/api/post/links/${currentPost.id}`);
        setPostLinks(response.data);
      } catch (error) {
        console.error('Error fetching links:', error);
        setPostLinks([]);
      }
    };

    fetchLinks();
  }, [currentPost?.id, base]);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (!currentPost?.id) return;
      
      try {
        const response = await axios.get(`${base}/api/post/saved/${currentPost.id}?userId=${userId}`);
        setIsSaved(response.data.saved);
      } catch (error) {
        console.error('Error fetching saved status:', error);
        setIsSaved(false);
      }
    };

    fetchSavedStatus();
  }, [currentPost?.id, userId, base]);

  useEffect(() => {
    const fetchSaveCount = async () => {
      if (!currentPost?.id) return;
      
      try {
        const response = await axios.get(`${base}/api/post/count/saves/${currentPost.id}`);
        setSaveCount(response.data.saveCount);
      } catch (error) {
        console.error('Error fetching save count:', error);
        setSaveCount(0);
      }
    };

    fetchSaveCount();
  }, [currentPost?.id, base]);

  const handleSave = async (e) => {
    e.stopPropagation();
    // Only allow saving/unsaving if it's not your own post
    if (userId !== currentPost.user_id) {
      try {
        const response = await axios.post(`${base}/api/post/togglesave`, {
          userId,
          postId: currentPost.id
        });
        setIsSaved(response.data.saved);
        // Update the save count based on the action
        setSaveCount(prevCount => response.data.saved ? prevCount + 1 : prevCount - 1);
      } catch (error) {
        console.error('Error toggling save:', error);
      }
    }
  };

  const closeSaved = () => {
    setShowSaved(false);
  };

  const handleMediaScroll = (direction) => {
    if (direction === 'next') {
      setCurrentMediaIndex(prev => 
        prev === currentPost.media.length - 1 ? 0 : prev + 1
      );
    } else if (direction === 'prev') {
      setCurrentMediaIndex(prev => 
        prev === 0 ? currentPost.media.length - 1 : prev - 1
      );
    }
  };

  // Add this new useEffect to reset currentMediaIndex when post changes
  useEffect(() => {
    setCurrentMediaIndex(0); // Reset to first media item when post changes
  }, [currentIndex]); // Dependency on currentIndex to detect post changes

  return (
    <div 
      className="full-screen-post-overlay" 
      onClick={onClose}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div 
        className="full-screen-post-content" 
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={handleCloseFullScreen}>
          <FaTimes />
        </button>
        <div className={`post-display ${(showComments || showLikes || showLinks || showSaved) ? 'with-sidebar' : ''}`}>
          <div className="media-container">
            {currentPost?.media?.length > 1 && (  // Add optional chaining
              <>
                {!isMobile && (
                  <>
                    <button 
                      className="media-nav-button left"
                      onClick={() => handleMediaScroll('prev')}
                    >
                      <FiChevronLeft />
                    </button>
                    <button 
                      className="media-nav-button right"
                      onClick={() => handleMediaScroll('next')}
                    >
                      <FiChevronRight />
                    </button>
                  </>
                )}
                <div className="media-dots">
                  {currentPost.media.map((_, index) => (
                    <div 
                      key={index} 
                      className={`media-dot ${index === currentMediaIndex ? 'active' : ''}`}
                      onClick={() => setCurrentMediaIndex(index)}
                    />
                  ))}
                </div>
              </>
            )}

            {/* Add safety checks for media access */}
            {currentPost?.media?.[currentMediaIndex] ? (
              currentPost.media[currentMediaIndex].media_type === 'video' ? (
                <video
                  className="post-video"
                  src={currentPost.media[currentMediaIndex].signedUrl}
                  controls
                />
              ) : (
                <img
                  className="post-image"
                  src={currentPost.media[currentMediaIndex].signedUrl}
                  alt={`Post ${currentMediaIndex + 1}`}
                />
              )
            ) : (
              // Fallback content when no media is available
              <div className="no-media-placeholder">
                No media available
              </div>
            )}
          </div>
          <div className={`post-overlay ${!currentPost.content ? 'no-caption' : ''}`}>
            <div className="post-info">
              <div className="post-user-info">
                <img 
                  src={userProfilePictures[currentPost.user_id]} 
                  className="post-user-avatar" 
                  alt="User avatar"
                />
                <span 
                  className="post-username"
                  onClick={() => handleUserClick(currentPost.user_id)}
                >
                  {currentPost.username || 'Unknown User'}
                </span>
              </div>
              {currentPost.content && (
                <div className="post-caption-container">
                  <p className="post-caption" style={{ WebkitLineClamp: showFullCaption ? 'unset' : '2' }}>
                    {currentPost.content}
                  </p>
                  {currentPost.content.length > 70 && (
                    <button className="show-more-button" onClick={toggleCaption}>
                      {showFullCaption ? 'Show less' : 'Show more'}
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="post-actions">
              <button className="like-button" onClick={handleLike}>
                <span>{currentPost.likeCount || 0}</span>
                {currentPost.user_id === userId ? (
                  <FaHeart />
                ) : (
                  currentPost.userLiked ? <FaHeart style={{ color: 'red' }} /> : <FaRegHeart />
                )}
              </button>
              <button className="comment-button" onClick={toggleComments}>
                <span>{currentPost.comment_count || currentPost.commentCount || 0}</span>
                <FaComment />
              </button>
              <button className="link-button" onClick={handleShareLink}>
                <FaLink />
              </button>
              <button 
                className="save-button" 
                onClick={handleSave}
                style={{ cursor: userId === currentPost.user_id ? 'default' : 'pointer' }}
              >
                <span>{currentPost.saveCount || saveCount || 0}</span>
                {userId === currentPost.user_id ? (
                  <FaBookmark style={{ color: '#808080' }} /> // Gray color for own posts
                ) : (
                  isSaved ? <FaBookmark style={{ color: '#FFD700' }} /> : <FaRegBookmark />
                )}
              </button>
            </div>
          </div>
        </div>
        <CommentSection
          post={currentPost}
          userId={userId}
          onCommentCountChange={onCommentCountChange}
          onClose={closeComments}
          isFullScreen={true}
          show={showComments}
        />
        {currentPost.user_id === userId && (
        <div className="likes-list-container">
          <LikesList
            postId={currentPost.id}
            onClose={closeLikes}
            onCloseAndNavigate={handleLikesListClose}
            isFullScreen={true}
            show={showLikes}
          />
        </div>
        )}
        <LinksList
          links={postLinks}
          onClose={closeLinks}
          show={showLinks}
        />
       
      </div>
    </div>
  );
};

export default FullScreenPost;
